<template>
  <div class="entity-manage bg-f">
    <!-- 表单-查询 -->
    <search-form
      :statusList="statusList"
      @setParams="setParams"
      :publishStatus="publishStatus"
      :groupList="groupList"
    ></search-form>
    <!-- 表格头-操作按钮区 -->
    <div class="bar"></div>
    <div class="operate-area dp-flex align-item-c p-16">
      <div class="mr-10"><i class="el-icon-s-operation mr-5"></i>数据列表</div>
      <!-- <el-button
        type="primary"
        size="small"
        icon="el-icon-refresh"
        @click="pullMainPage"
        :loading="pullLoading"
        id="pullMainPageEvent"
        >拉取主页</el-button
      > -->
      <el-button
        type="primary"
        size="small"
        v-if="!isOptimize"
        :disabled="!multipleSelection.length"
        @click="batchTipSet(false)"
        >批量提醒设置</el-button
      >
      <!-- <el-button
        type="primary"
        size="small"
        @click="handleExport"
        v-if="!isOptimize"
        >导出</el-button
      > -->
    </div>
    <!-- 表格-列表 -->
    <div class="table-wrap p-16">
      <cus-table
        ref="cusTableRef"
        :tableParams="tableParams"
        :tableHeightData="tableHeightData"
        :tableData="tableData"
        :colData="colData"
        @select-change="selectChange"
        @sort-change="sortChange"
      >
        <template v-slot:colslot="{ scope, prop }">
          <div v-if="prop == 'name'">
            <span class="right-margin">{{ scope.row[prop] }}</span>
            <el-tooltip content="点击复制" placement="top-end"
              ><el-button
                @click="copyMainPage(scope, 'copy')"
                type="text"
                icon="el-icon-document-copy"
              ></el-button
            ></el-tooltip>
            <el-tooltip content="点击打开主页" placement="top-end"
              ><el-button
                @click="copyMainPage(scope, 'open')"
                type="text"
                icon="el-icon-connection"
              ></el-button
            ></el-tooltip>
          </div>
          <div v-else-if="prop == 'num'">
            <el-row :gutter="15">
              <el-col :span="24">
                <div
                  v-if="
                    scope.row.fb_users &&
                      scope.row.fb_users.length
                  "
                >
                  <p
                    class="right-margin"
                    v-for="item in scope.row.fb_users"
                    :key="item"
                    style="text-align:center;"
                  >
                    {{ item }}
                  </p>
                </div>
              </el-col>
              <!-- <el-col :span="5">
                <el-link
                  @click="
                    numInfoPreserve(
                      scope.row.thirdLoginUsers,
                      scope.row.id,
                      scope.$index
                    )
                  "
                  type="primary"
                  v-if="!isOptimize"
                  >更多</el-link
                >
              </el-col> -->
            </el-row>
          </div>
          <div v-else-if="prop == 'status'">
            {{
              statusList.find((s) => s.key == scope.row[prop])
                ? statusList.find((s) => s.key == scope.row[prop]).value
                : "--"
            }}
          </div>
          <!-- 设置最后一次更新时间 -->
          <div v-else-if="prop == 'last_update_date'">
            <div v-if="!scope.row.editDate">
              {{ (scope.row.last_update_date) || "--" }}
              <el-button type="text" size="mini" @click="setEditDate(scope.$index,true)">编辑</el-button>
            </div>
            <div v-else>
              <el-date-picker
                v-model="scope.row.last_update_date"
                type="date"
                placeholder="选择日期"
                size="mini"
                style="width:160px"
                @change="selectDate"
              >
              </el-date-picker>
              <el-button type="text" size="mini" @click="sureDate(scope.row,scope.$index)">确定</el-button>
              <el-button type="text" size="mini" @click="setEditDate(scope.$index,false)">取消</el-button>
            </div>
          </div>
          <div v-else-if="prop == 'isPublished'">
            {{ scope.row.is_published ? "已发布" : "未发布" }}
          </div>
          <div v-else-if="prop == 'overallStarRating'">
            {{
              scope.row.overall_star_rating || scope.row.overall_star_rating == 0
                ? scope.row.overall_star_rating
                : "--"
            }}
          </div>
          <div v-else-if="prop == 'infoStatus'">
            {{ scope.row.fill_status == "NOT_FILL" ? "未填写" : "已填写" }}
          </div>
          <div v-else-if="prop == 'accountId'">
            <el-link
              v-if="scope.row.account_count"
              @click="accountInfoView(scope, 'accountInfoVisible')"
              type="primary"
              >{{ scope.row.account_count }}</el-link
            >
            <span v-else>{{ scope.row.account_count }}</span>
          </div>
          <div v-else-if="prop == 'batchMsg'">
            <el-link
              @click="accountInfoView(scope, 'batchMsgVisible')"
              type="primary"
              >查看</el-link
            >
          </div>
          <div v-else-if="prop == 'server'">
            <div v-if="scope.row.alert_users &&  scope.row.alert_users.length">
              <p v-for="item in scope.row.alert_users" :key="item.id">
                {{ item}}
              </p>
            </div>
            <span v-else>--</span>
          </div>
          <div v-else-if="prop == 'operate'">
            <el-link
              class="operate-link"
              type="primary"
              @click="batchTipSet(scope.row)"
              :disabled="isOptimize"
              >设置提醒</el-link
            ><el-link
              type="primary"
              @click="editMainPage(scope.row)"
              :disabled="isOptimize"
              id="editMainPageEvent"
              >编辑</el-link
            >
          </div>
        </template>
      </cus-table>
      <div class="page" style="margin-top: 10px; text-align: right">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[1, 10, 20, 30, 40, 50, 80, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 批量提醒设置 -->
    <batch-tip-set
      :visible="batchTipSetVisible"
      title="提醒设置"
      :initData="batchTipSetInitData"
      :ids="ids"
      :users="users"
      @getList="getList"
      @close-dialog="batchTipSetVisible = false"
    ></batch-tip-set>
    <!-- 编辑主页 -->
    <edit-main-page
      :visible="mainPageVisible"
      title="编辑主页"
      :initData="mainPageInitData"
      :statusList="statusList"
      @getList="getList"
      @close-dialog="mainPageVisible = false"
    ></edit-main-page>
    <!-- 主页个号信息维护 -->
    <num-info-preserve
      :visible="numInfoVisible"
      title="主页个号信息维护"
      :colData="numInfoColData"
      :numList="numList"
      :userTypesList="userTypesList"
      :page_id="page_id"
      @refreshCol="refreshCol"
      @close-dialog="numInfoVisible = false"
    ></num-info-preserve>
    <!-- 广告账户详情查看 -->
    <ad-account-info
      :visible="accountInfoVisible"
      title="广告账户详情查看"
      :tableData="accountInfoTableData"
      :colData="accountInfoColData"
      :page_id="page_id"
      @close-dialog="accountInfoVisible = false"
    ></ad-account-info>
    <!-- 开户批次信息 -->
    <batch-msg
      :visible="batchMsgVisible"
      title="开户批次信息"
      :tableData="accountInfoTableData"
      :colData="accountInfoColData"
      :page_id="page_id"
      @close-dialog="batchMsgVisible = false"
    ></batch-msg>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import numInfoPreserve from "./components/dialog/numInfoPreserve";
import adAccountInfo from "./components/dialog/adAccountInfo";
import batchMsg from "./components/dialog/batchMsg";
import batchTipSet from "./components/dialog/batchTipSet";
import editMainPage from "./components/dialog/editMainPage";
import searchForm from "./components/searchForm";
import cusTable from "@/views/components/cusTable";
import {
  pullPages,
  pages,
  status,
  userTypes,
  exportPage,
  setLastUpdateDate,
  groups
} from "@/api/mainPage";
import {fnCSTToYmd} from '@/utils/utils';
export default {
  components: {
    numInfoPreserve,
    adAccountInfo,
    batchTipSet,
    editMainPage,
    searchForm,
    cusTable,
    batchMsg,
  },
  data() {
    return {
      tableParams: {
        className: "entity-table",
        isTableHeight: true,
        border: true,
        selection: true,
      },
      tableHeightData: [
        { selector: ".navbar", cssProperty: "height" },
        { selector: ".app-main", cssProperty: "paddingTop" },
        { selector: ".app-main", cssProperty: "paddingBottom" },
        { selector: ".entity-manage .search-form", cssProperty: "height" },
        { selector: ".entity-manage .bar", cssProperty: "height" },
        { selector: ".entity-manage .operate-area", cssProperty: "height" },
        { selector: ".entity-manage .table-wrap", cssProperty: "paddingTop" },
        {
          selector: ".entity-manage .table-wrap",
          cssProperty: "paddingBottom",
        },
      ],
      tableData: [],
      pageSize: 20,
      currentPage: 1,
      total: 0,
      colData: [
        {
          label: "主页ID",
          prop: "id",
          align: "center",
          headerAlign: "center",
          fixed: "left",
          minWidth: "180",
        },
        {
          label: "主页名称",
          prop: "name",
          align: "center",
          headerAlign: "center",
          fixed: "left",
          minWidth: "220",
        },
        {
          label: "主页状态",
          prop: "status",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "主页发布状态",
          prop: "isPublished",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "最近更新时间",
          prop: "last_update_date",
          align: "center",
          headerAlign: "center",
          sortable:true,
          minWidth: "280",
        },
        {
          label: "运营个号",
          prop: "num",
          align: "center",
          headerAlign: "center",
          minWidth: "240",
        },
        {
          label: "主页评分",
          prop: "overallStarRating",
          align: "center",
          headerAlign: "center",
          sortable:true,
          minWidth: "120",
        },
        {
          label: "主页资料填写状态",
          prop: "infoStatus",
          align: "center",
          headerAlign: "center",
          minWidth: "140",
        },
        {
          label: "可用广告账户数",
          prop: "accountId",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "所属家族",
          prop: "org_name",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "开户批次信息",
          prop: "batchMsg",
          align: "center",
          headerAlign: "center",
          minWidth: "120",
        },
        {
          label: "通知人",
          prop: "server",
          align: "center",
          width: "180px",
          headerAlign: "center",
        },
        {
          label: "操作",
          prop: "operate",
          align: "center",
          headerAlign: "center",
          width: "150px",
          fixed: "right",
        },
      ],
      multipleSelection: [],
      optimizers: [],
      teams: [],
      families: [],
      groupList:[],
      statusList: [],
      //
      batchTipSetVisible: false,
      batchTipSetInitData: {},
      //
      mainPageVisible: false,
      mainPageInitData: {},
      //
      numInfoVisible: false,
      numInfoInitData: {},
      numInfoTableData: [
        {
          numId: "个号ID1",
        },
        { numId: "个号ID2" },
      ],
      numInfoColData: [
        {
          label: "个号ID",
          prop: "thirdUserId",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "个号名称",
          prop: "thirdUserName",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "个号邮箱",
          prop: "thirdUserEmail",
          align: "center",
          headerAlign: "center",
          minWidth: "120",
        },
        {
          label: "个号角色",
          prop: "userType",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "操作",
          prop: "operate",
          align: "center",
          headerAlign: "center",
        },
      ],
      //
      accountInfoVisible: false,
      batchMsgVisible: false,
      accountInfoTableData: [],
      accountInfoColData: [
        {
          label: "广告账户",
          prop: "accountId",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "开户批次号",
          prop: "batch_num",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "代理商",
          prop: "agent",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "注册日期",
          prop: "registTime",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "使用状态",
          prop: "applyStatus",
          align: "center",
          headerAlign: "center",
        },
      ],
      numList: {},
      userTypesList: [],
      page_id: null,
      ids: [],
      users: [],
      pullLoading: false,
      searchPrams: {},
      publishStatus: [
        { id: true, name: "已发布" },
        { id: false, name: "未发布" },
      ],
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    isOptimize() {
      if (
        (this.userInfo.data.roles.length == 1 &&
          this.userInfo.data.roles[0] == 7) ||
        (this.userInfo.data.roles.length == 1 &&
          this.userInfo.data.roles[0] == 6) ||
        (this.userInfo.data.roles.length == 2 &&
          this.userInfo.data.roles.includes(6) &&
          this.userInfo.data.roles.includes(7))
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    // 主页个号信息维护
    numInfoPreserve(msg, id, index) {
      console.log(id);
      // numInfoTableData
      // numInfoColData
      this.numList = msg;
      this.page_id = id;
      this.index = index;
      this.numInfoVisible = true;
    },
    refreshCol(val) {
      console.log(val, this.index);
      this.tableData[this.index].thirdOperationUser.thirdUserAndTypes = val;
      this.$set(this.tableData, this.index, this.tableData[this.index]);
      console.log(this.tableData[this.index]);
    },
    accountInfoView(scope, type) {
      this.page_id = scope.row.id;
      this[type] = true;
    },
    handleCopy(data) {
      let _input = document.createElement("input");
      _input.value = data;
      document.body.appendChild(_input);
      _input.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: "复制成功",
        type: "success",
      });
      _input.remove();
    },
    copyMainPage(scope, type) {
      if (type == "copy") {
        this.handleCopy(scope.row.link);
      } else {
        window.open(scope.row.link);
      }
    },
    pullMainPage() {
      this.pullLoading = true;
      pullPages().then((res) => {
        this.pullLoading = false;
      });
    },
    batchTipSet(row) {
      if (row) {
        this.ids = [row.id];
        this.users = (row.ext && row.ext.alert_user)?row.ext.alert_user.map((v) => v.id): [];
      } else {
        this.users = [];
        this.ids = this.multipleSelection.map((v) => v.id);
      }

      this.batchTipSetInitData = row ? { ...row } : {};
      this.batchTipSetVisible = true;
    },
    editMainPage(row) {
      this.mainPageInitData = row ? { ...row } : {};
      this.mainPageVisible = true;
    },
    handleExport() {
      exportPage({
        page_size: this.pageSize,
        page_num: this.currentPage,
        ...this.searchPrams,
      });
      // window.open()
    },
    selectChange(val) {
      this.multipleSelection = [...val];
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    sortChange(v){
      console.log(v);
      let params = null;
      if(v.order){
        params = {order:v.order,prop:v.prop};
      }
      this.getList(params)

    },
    pullList() {
      pullPages().then((res) => {});
    },
    setParams(val) {
      this.currentPage = 1;
      this.searchPrams = { ...val };
      this.getList();
    },
    getList(sortParams) {
      let params = {
        page_size: this.pageSize,
        page_num: this.currentPage,
        ...this.searchPrams,
        sort_params:sortParams?{...sortParams}:null
      };
      pages(params).then((res) => {
        this.tableData = res.data.content;
        this.tableData.map((v,index) => {
          v.status = v.marked_status;
          v.update_time = v.last_update_time;
          v.infoStatus = v.fill_status;
          v.org_name = v.belong_family;
          v.editDate = false;
          this.$set(v,'last_update_date',v.last_update_date?v.last_update_date: '')
          v.old_last_update_time = v.last_update_date;
          this.$set(this.tableData,index,v)
        });
        this.total = res.data.totalElements;
      });
    },
    getStatus() {
      status().then((res) => {
        this.statusList = res.data;
      });
      groups().then(res=>{
        this.groupList = res.data;
      })
    },
    getUserType() {
      userTypes().then((res) => {
        this.userTypesList = res.data;
      });
    },
    // 点击编辑日期
    setEditDate(index,val){
      if(!val){
        this.tableData[index].last_update_date = this.tableData[index].old_last_update_time;
      }
      this.tableData[index].editDate = val;
      this.$set(this.tableData,index,JSON.parse(JSON.stringify(this.tableData[index])));
    },
    // 选择日期
    selectDate(v){
      // console.log(v);
    },
    // 确认日期
    sureDate(info,index){      
      if(!info.last_update_date){
        this.$message({
          type:'warning',
          message:'请选择时间'
        })
        return false
      }
      let params = {
        page_id:info.id,
        last_update_date:fnCSTToYmd(new Date(info.last_update_date),{tag:1}) || null
      }
      setLastUpdateDate(params).then(res=>{
        if(res.code == 0){
          this.tableData[index].last_update_date = fnCSTToYmd(new Date(info.last_update_date),{tag:1});
          this.tableData[index].old_last_update_time = fnCSTToYmd(new Date(info.last_update_date),{tag:1})
          this.setEditDate(index,false);
          this.$message({
            type:'success',
            message:'修改成功'
          })
        }else{
          this.tableData[index].last_update_date = fnCSTToYmd(new Date(this.tableData[index].old_last_update_time),{tag:1});
        }
      })
    }
  },
  mounted() {
    this.getList();
    this.getStatus();
    // this.getUserType();
  },
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.entity-manage {
  .bar {
    height: 16px;
    background-color: #f5f5f5;
  }
  .operate-area {
    background-color: #fafafa;
  }
  .entity-table {
    th {
      background-color: #fafafa;
    }
  }
}
</style>
<style lang="scss" scoped>
.operate-link,
.right-margin {
  margin-right: 10px;
}
</style>
